function toggleMenu() {
    let element = document.getElementById("menu");
    let buttonClass = document.getElementById('hamburger');
    let overlay = document.getElementById('menuOverlay');
    element.classList.toggle("active");
    buttonClass.classList.toggle('active');
    overlay.classList.toggle('active');
}

document.addEventListener('DOMContentLoaded', function() {
    const selector = '.header__nav-link';
    const elems = Array.from( document.querySelectorAll( selector ) );
    console.log(elems);
    const navigation = document.querySelector( 'nav' );

    function makeActive( evt ) {
        const target = evt.target;

        if ( !target || !target.matches( selector ) ) {
            return;
        }

        elems.forEach( elem => elem.classList.remove( 'active' ) );

        evt.target.classList.add( 'active' );
    };

    navigation.addEventListener( 'mousedown', makeActive );

} );
